<div class="profile-info">
  <mat-icon>account_circle</mat-icon>
  <span class="!text-sm">{{ this.userName }}</span>
  <div class="mt-2">
    <span class="font-bold">Logged in to:</span>
    <button mat-raised-button color="primary" type="button" class="w-full mt-2">
      {{ this.currentClient }}
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<mat-nav-list>
  <a
    mat-list-item
    routerLink="/dashboard"
    routerLinkActive="router-link-active"
    class="menu-item"
    *ngIf="
      sessionService.hasAnyRole([roleConstantsService.roles.CAN_VIEW_DASHBOARD])
    "
    ><mat-icon matListItemIcon>home</mat-icon>Dashboard</a
  >
  <a
    mat-list-item
    routerLink="account/search"
    routerLinkActive="router-link-active"
    class="menu-item"
    *ngIf="
      sessionService.hasAnyRole([roleConstantsService.roles.CAN_VIEW_ACCOUNTS])
    "
    ><mat-icon matListItemIcon>person_search</mat-icon>Accounts</a
  >
  <a
    mat-list-item
    routerLink="document/search"
    routerLinkActive="router-link-active"
    class="menu-item"
    *ngIf="
      sessionService.hasAnyRole([roleConstantsService.roles.CAN_VIEW_DOCUMENTS])
    "
    ><mat-icon matListItemIcon>description</mat-icon>Documents</a
  >
  <a
    mat-list-item
    routerLink="job/search"
    routerLinkActive="router-link-active"
    class="menu-item"
    *ngIf="
      sessionService.hasAnyRole([roleConstantsService.roles.CAN_VIEW_JOBS])
    "
    ><mat-icon matListItemIcon>cloud_upload</mat-icon>Job Processing</a
  >
  <mat-expansion-panel
    class="menu-expansion"
    *ngIf="
      sessionService.hasAnyRole([
        roleConstantsService.roles.CAN_VIEW_GROUPS,
        roleConstantsService.roles.CAN_VIEW_USERS,
        roleConstantsService.roles.CAN_VIEW_CLIENT_SETUP
      ])
    "
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon matListItemIcon>admin_panel_settings</mat-icon>
        Administration
      </mat-panel-title>
    </mat-expansion-panel-header>

    <a
      mat-list-item
      routerLink="users"
      routerLinkActive="router-link-active"
      class="menu-item"
      *ngIf="
        sessionService.hasAnyRole([roleConstantsService.roles.CAN_VIEW_USERS])
      "
    >
      <mat-icon matListItemIcon>manage_accounts</mat-icon>Users
    </a>

    <a
      mat-list-item
      routerLink="groups"
      routerLinkActive="router-link-active"
      class="menu-item"
      *ngIf="
        sessionService.hasAnyRole([roleConstantsService.roles.CAN_VIEW_GROUPS])
      "
    >
      <mat-icon matListItemIcon>groups</mat-icon>Groups
    </a>

    <a
      mat-list-item
      routerLink="administration/setup"
      routerLinkActive="router-link-active"
      class="menu-item"
      *ngIf="
        sessionService.hasAnyRole([
          roleConstantsService.roles.CAN_VIEW_CLIENT_SETUP
        ])
      "
    >
      <mat-icon matListItemIcon>settings</mat-icon>Client Setup
    </a>
  </mat-expansion-panel>
  <mat-divider></mat-divider>
  <a mat-list-item class="menu-item" (click)="logout()"
    ><mat-icon matListItemIcon>logout</mat-icon>Logout</a
  >
</mat-nav-list>
