import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Roles } from './core/constants/roles.constants';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { MasterLayoutComponent } from './layouts/master-layout/master-layout.component';

const routes: Routes = [
  {
    path: 'auth',
    component: MasterLayoutComponent,
    loadChildren: () =>
      import('./routes/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: MasterLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./routes/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.CAN_VIEW_DASHBOARD, Roles.CAN_EDIT_DASHBOARD] },
      },
      {
        path: 'document',
        loadChildren: () =>
          import('./routes/document/document.module').then(
            (m) => m.DocumentModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.CAN_VIEW_DOCUMENTS] },
      },
      {
        path: 'job',
        loadChildren: () =>
          import('./routes/job/job.module').then((m) => m.JobModule),
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./routes/administration/administration.module').then(
            (m) => m.AdministrationModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.CAN_VIEW_CLIENT_SETUP] },
      },
    ],
  },
  { path: '**', redirectTo: 'auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
