import { Component } from '@angular/core';
import { RoleConstantsService } from '../../services/roleconstants.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  userName: string | null = '';
  currentClient: string | null = '';

  constructor(
    public sessionService: SessionService,
    public roleConstantsService: RoleConstantsService
  ) {}

  ngOnInit() {
    // TODO: Make these observables so that we can subscribe to the value and update the UI?
    this.userName = this.sessionService.userName;
    this.currentClient = this.sessionService.getDefaultAccount()?.clientName!;
  }

  logout(): void {
    this.sessionService.logout();
  }
}
