import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AuthService } from '../../core/services/data/auth.service'; // Assuming you have an AuthService
import { LoaderService } from '../../core/services/loader.service';
import { PageTitleService } from '../../core/services/pagetitle.service';
import { RoleConstantsService } from '../../core/services/roleconstants.service';
import { SessionService } from '../../core/services/session.service';
import { fadeAnimation } from '../../shared/animations';

@Component({
  selector: 'app-master-layout',
  templateUrl: './master-layout.component.html',
  styleUrls: ['./master-layout.component.scss'],
  animations: [fadeAnimation],
})
export class MasterLayoutComponent implements AfterViewInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  isAuthenticated: boolean = true;
  isMobile: boolean = true;
  isCollapsed: boolean = true;

  pageTitle: string = '';

  constructor(
    public roleConstantsService: RoleConstantsService,
    public sessionService: SessionService,
    private authService: AuthService,
    private pageTitleService: PageTitleService,
    private siteTitleService: Title,
    private observer: BreakpointObserver,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loaderService.show();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loaderService.hide();
      }
    });
  }

  ngAfterViewInit(): void {
    document.querySelectorAll('.shape').forEach((shape) => {
      const randomX = Math.floor(Math.random() * 20) - 20;
      const randomY = Math.floor(Math.random() * 20) - 20;
      shape.setAttribute(
        'style',
        `--random-x: ${randomX}px; --random-y: ${randomY}px;`
      );
    });
  }

  ngOnInit() {
    // initial check of authentication
    this.isAuthenticated = this.sessionService.isAuthenticated;

    this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
      if (screenSize.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

    //This should keep us in the loop on if a user is still authed or
    this.authService.isAuthenticated$.subscribe((authStatus) => {
      this.isAuthenticated = authStatus;
    });

    this.pageTitleService.title$.subscribe((title: string) => {
      setTimeout(() => {
        this.pageTitle = title;

        const siteTitle = this.siteTitleService.getTitle();
        const fullTitle = `${siteTitle} ${title}`;

        this.siteTitleService.setTitle(fullTitle);
      });
    });
  }

  toggleMenu() {
    if (this.isMobile) {
      this.sidenav.toggle();
      this.isCollapsed = false;
    } else {
      this.sidenav.open();
      this.isCollapsed = false;
    }
  }
}
