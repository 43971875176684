import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './core/components/side-nav/side-nav.component';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JWTInterceptor } from './core/interceptors/jwt.interceptor';
import { MasterLayoutComponent } from './layouts/master-layout/master-layout.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { CommonMaterial } from './shared/modules/common-material.module';
@NgModule({
  declarations: [
    AppComponent,
    MasterLayoutComponent,
    LoaderComponent,
    SideNavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CommonModule,
    CommonMaterial,
    ReactiveFormsModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    [Title],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
