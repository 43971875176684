import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionService } from '../services/session.service';
import { RoleConstantsService } from '../services/roleconstants.service';

/**
 * Checking our role provider if the current route can be accessed with its permissions.
 */
@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private roleConstantsService: RoleConstantsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const routeRoles: string[] = route.data['roles'];

    if (!routeRoles) {
      return of(true);
    }

    const requiredRoles: string[] = [
      this.roleConstantsService.roles.ADMIN,
      ...routeRoles,
    ];

    try {
      if (this.sessionService.hasAnyRole(requiredRoles)) {
        return of(true);
      } else {
        // This may seem harsh but logging out the user if they don't have perms.
        this.sessionService.logout();
        return of(false);
      }
    } catch {
      return of(false);
    }
  }
}
