<app-loader></app-loader>
<div class="h-screen flex flex-col">
  <header class="master-header p-4">
    <mat-toolbar class="master-header">
      <button
        mat-icon-button
        class="mr-10"
        (click)="toggleMenu()"
        *ngIf="isMobile"
      >
        <mat-icon class="text-white">menu</mat-icon>
      </button>
      <img
        src="images/logo.svg"
        data-src="images/logo.svg"
        alt="OSG Document Connect"
        class="h-14 mr-4"
      />
      <div class="text-white">
        <span class="block text-xl font-bold">Document</span>
        <span class="block text-xl font-bold">Connect</span>
      </div>
    </mat-toolbar>
  </header>

  <mat-sidenav-container>
    <mat-sidenav
    [ngClass]="!isCollapsed ? 'expanded' : ''"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="isMobile ? 'false' : 'true'"
    *ngIf="isAuthenticated"
    >
      <app-side-nav>
        
      </app-side-nav>
    </mat-sidenav>

    <mat-sidenav-content class="!flex flex-col flex-grow bg-gray-100">
      <header class="flex flex-col page-header" *ngIf="isAuthenticated">
        <mat-toolbar class="page-header">
          <span class="page-header-title">{{ pageTitle }}</span>
        </mat-toolbar>
      </header>
      <main class="flex flex-col flex-grow items-center justify-center">
        <div class="background">
          <div class="shape shape-1"></div>
          <div class="shape shape-2"></div>
          <div class="shape shape-3"></div>
          <div class="shape shape-4"></div>
          <div class="shape shape-5"></div>
          <div class="shape shape-6"></div>
        </div>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <footer class="flex flex-col bg-gray-800 text-white text-center p-4 z-10" *ngIf="!isAuthenticated">
    &copy; 2024 OSG. All rights reserved.
  </footer>
</div>
