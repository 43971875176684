import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { SessionService } from '../services/session.service';

/**
 * Intercepting every request and adding our JWT token to the headers.
 */
@Injectable({
  providedIn: 'root',
})
export class JWTInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (httpRequest.url.includes('auth/refresh')) {
      return next.handle(httpRequest);
    }
    
    //Check if our token needs a refresh, and log user out on any refresh failure
    return this.sessionService.checkAndRefreshTokenIfNeeded().pipe(
      switchMap(() => {
        const token = this.sessionService.authToken;
        let authorizationRequest = httpRequest;

        if (token) {
          this.sessionService.setSessionFromToken(token);

          authorizationRequest = httpRequest.clone({
            headers: httpRequest.headers.set('x-api-key', token),
          });
        }

        return next.handle(authorizationRequest);
      }),
      catchError((error) => {
        this.sessionService.logout();
        return throwError(() => new Error(error));
      })
    );
  }
}
